.txt-red {
  color: red;
  font-weight: bold;
}
.txt-green {
  color: green;
  font-weight: bold;
}
.txt-blue {
  color: blue;
  font-weight: bold;
}
.txt-yellow {
  color: #9f9f03;
  font-weight: bold;
}
#tblHistoryTransaction td {
  padding: 0.3rem;
}

.card_custom {
  background: #ffe3e8;
}

.td-nap-tien {
  background: #8cff6a;
}
