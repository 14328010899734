//
// Header
//

.header {
    position: relative;
}

.header-personal {
    display: flex;
    flex-direction: column;
    color: white;
}

.bg-info {
    background-color: #d42027 !important;
}

@include media-breakpoint-down(sm) {
    .avatar-header {
        display: none;
    }
}

@media (max-width: 400px) {
    .header-main {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 1rem;
    }
}